<template>
  <div>

    <k-form-design @save="handleSave" :title="title" :parentJsonData="jsonData" :style="{ height: '70vh' }" />

  </div>
</template>

<script>
import requestApi from "@/api/flow.js";
export default {
  data() {
    return {
      jsonData: {
        list: [],
        formData:{},
        config: {
          layout: "horizontal",
          labelCol: { xs: 4, sm: 4, md: 4, lg: 4, xl: 4, xxl: 4 },
          labelWidth: 100,
          labelLayout: "flex",
          wrapperCol: { xs: 18, sm: 18, md: 18, lg: 18, xl: 18, xxl: 18 },
          hideRequiredMark: false,
          customStyle: ""
        }
      },
      copyConfig: { copyText: "复制", copiedText: "已复制" },
      title:"表单设计器"
    };
  },
  created() {
      this.initJsonData();
  },
  methods: {
    handleSave(data) {
      
      // 在这个方法中编写保存事件的逻辑
      // 可以将 this.formData 发送到服务器保存数据，或者执行其他保存操作
      this.$confirm("是否提交 【"+this.formData.value+ '】 表单数据 ', '友情提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.jsonData = data
        
        this.submit();
      }).catch(() => {

      });
    },
    initJsonData() {
      let data = JSON.parse( localStorage.getItem("formData"));
      
      if(data!=undefined  && data[0].context!=""){
        this.formData = data[0];
        this.jsonData =JSON.parse(this.formData.context) 
        this.title= this.title +" — "+ this.formData.value +"（编辑）"

      }
     
    },
    submit() {
      
      let form = { recordId: this.formData.key, name: this.formData.value, jsonData: this.jsonData }
      this.$post(requestApi.formOperate, form).then((res) => {
        if (res.success && res.statusCode == 200) {
          this.$confirm('是否返回工作流页面？', '友情提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            localStorage.removeItem("formData")
            this.$router.push("flow");
            
          }).catch(() => {

          });

        }
      });
    }
  }
};
</script>
